<template>
    <div ref="lottieContainer" class="lottie-animation"></div>
</template>
  
<script>
    import lottie from "lottie-web";
  
    export default {
        name: "LottieAnimation",
        props: {
            animationData: {
                type: Object,
                required: true, // The JSON animation data
            },
            loop: {
                type: Boolean,
                default: true, // Should the animation loop
            },
            autoplay: {
                type: Boolean,
                default: true, // Should the animation start playing immediately
            },
        },
        mounted() {
            lottie.loadAnimation({
                container: this.$refs.lottieContainer, // The DOM element to render the animation
                animationData: this.animationData, // The JSON animation data
                renderer: "svg", // Render using SVG
                loop: this.loop,
                autoplay: this.autoplay,
            });
        },
        beforeDestroy() {
            lottie.destroy();
        },
    };
</script>

<style scoped>
    .lottie-animation {
        width: 50%;
        height: 50%;
    }
</style>
