<template>
    <div class="overlay">
        <v-container fluid class="h-full">
            <div class="swipe-gestures" v-if="showAnimation">
                <lottie-animation :animationData="animationData" />
                <p>{{ $t('dialogs.video_guide.swipe_gestures') }}</p>
            </div>
            <v-row align="center" justify="center" class="h-full" v-else>
                <v-col>
                    <div class="dialog">

                        <v-row class="pb-6">
                            <v-col @click.stop="$emit('left')" class="yes col-4">
                                <div class="circle-style">
                                    <span class="choose yes">
                                        <v-icon size="60">mdi-chevron-left</v-icon>
                                        {{ $t('dialogs.tap_back') }}
                                    </span>
                                </div>
                            </v-col>

                            <v-col @click.stop="$emit('center')" class="center col-4">
                                <div class="circle-style">
                                    <span class="choose center">
                                        <pause-icon></pause-icon>
                                        <!-- <v-icon size="60">mdi-pause</v-icon> -->
                                        {{ $t('dialogs.pause') }}
                                    </span>
                                </div>
                            </v-col>

                            <v-col @click.stop="$emit('right')" class="no col-4">
                                <div class="circle-style">
                                    <span  class="choose no">
                                        <v-icon size="60">mdi-chevron-right</v-icon>
                                        {{ $t('dialogs.tap_next') }}
                                    </span>
                                </div>
                            </v-col>
                        </v-row>
                        <slot name="afterButtons"></slot>
                    </div>
                </v-col>
            </v-row>

        </v-container>

    </div>
</template>

<script>
import PauseIcon from "@/assets/images/icons/pause.svg?inline";
import LottieAnimation from "@/components/app/common/LottieAnimation";
import animationData from "@/assets/animation.json";

export default {
    name: "StoryGuide",
    components: {
        PauseIcon,
        LottieAnimation,
    },
    data() {
        return {
            animationData,
            showAnimation: false,
        };
    },
    mounted() {
        setTimeout(() => {
            this.showAnimation = true;

            // Display the swipe-gestures for another 2 seconds
            setTimeout(() => {
                this.$emit("close");
            }, 2000);
        }, 4000);
    },
}
</script>

<style scoped lang="scss">
@import '@/sass/modules/_variables';

.dashed-lines-left:after { 
    content:"";
    position: absolute;
    z-index: -1;
    top: -50px;
    bottom: -50px;
    left: 30%;
    border-left: 2px dashed #FFFFFF;
}

.dashed-lines-right:after { 
    content:"";
    position: absolute;
    z-index: -1;
    top: -50px;
    bottom: -50px;
    right: 30%;
    border-left: 2px dashed #FFFFFF;
}

.yes{
    justify-content: center;
    display: flex;
    align-items: center;
}

.no { 
    justify-content: center;
    display: flex;
    align-items: center;
 }

 .center {
    justify-content: center;
    display: flex;
    align-items: center;
 }

.choose {
    // border: 1px solid white;
    // border-radius: 13px;
    transition: 0.3s;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    color: #FFFFFF;
}

.choose i {
    color: #000000;
    margin-top: 5px;
} 

.no .circle-style, .yes .circle-style, .center .circle-style { 
    background-color: #FFFFFF; 
    height: 70px;
    width: 70px;
    border-radius: 100%;
}

.overlay {
    position: fixed;
    background: rgba(52, 52, 65, 0.88);
    z-index: 1000;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.swipe-gestures { width: 100%; position: absolute; top: 50%; left: 50%; padding: 30px 10px; display: flex; flex-direction: column; justify-items: center; align-items: center; justify-content: center; transform: translate(-50%, -50%); }
</style>
