<template>
    <before-posting>
        <v-container fluid>
            <v-row class="top-text pb-8" no-gutters align="center">
                <v-col
                    cols="2"
                    class="back-icon"
                    @click="$store.commit('setStep', 1)"
                >
                    <v-icon size="33">mdi-chevron-left</v-icon>
                </v-col>
                <slot name="title"></slot>
            </v-row>
            <v-row>
                <v-col class="pb-0" style="position: relative">
                    <autocomplete-hashtags
                        ref="story_description"
                        name="input-7-4"
                        :class="{
                            'before-posting__text': true,
                            block: true,
                            'middle-textarea-placeholder': middleTextareaText,
                        }"
                        rows="1"
                        :placeholder="$t('dialogs.write_something')"
                        :maxlength="storyDescriptionLength"
                        v-model="model.text"
                    >
                    </autocomplete-hashtags>
                </v-col>
            </v-row>
            <v-radio-group
                class="mt-5 pt-0"
                v-model="model.destination"
                column
                hide-details
            >
                <simple-before-posting-block
                    value="my_story"
                    @click="onClickDestination"
                >
                    <div class="title-block__before-posting">
                        {{ $t("dialogs.my_story") }}
                    </div>
                </simple-before-posting-block>

                <v-expansion-panels @change-radio="handleChangeRadio">
                    <checkboxes
                        v-if="model.destination === 'my_story'"
                        :title="$t('dialogs.tags')"
                        :hint="$t('dialogs.tags_hint')"
                        :data="tags"
                        :display-tag-title="false"
                        :max-count="3"
                        v-model="model.tags"
                        class="mt-5"
                    />
                    <checkboxes
                        v-if="
                            !!subscribers && Object.values(subscribers).length
                        "
                        :radio="{ has: true, value: 'subscribers' }"
                        :title="$t('dialogs.subscribers')"
                        :data="subscribers"
                        :with-avatars="true"
                        :clear="clearSubscribers"
                        :checked-all="checkedSubscribers"
                        v-model="model.subscribers"
                        class="mt-5"
                        @change-radio="handleChangeRadio"
                        @click="onClickDestination"
                    />
                    <checkboxes
                        v-if="selfChats.length"
                        :radio="{ has: true, value: 'chat' }"
                        :title="$t('dialogs.chat')"
                        :data="selfChats"
                        :with-avatars="true"
                        :clear="clearChats"
                        :checked-all="checkedChats"
                        class="chat mt-5"
                        v-model="model.chats"
                        @change-radio="handleChangeRadio"
                        @click="onClickDestination"
                    />
                </v-expansion-panels>
            </v-radio-group>
            <v-radio-group v-model="model.free">
                <simple-before-posting-block
                    value="free"
                    class="price"
                    @click="onClickFree"
                >
                    <template v-slot:prepend>
                        {{ currency }}
                    </template>
                    <v-row align="center">
                        <v-col cols="7" class="pb-0 pt-0" @click="onClickPrice">
                            <v-text-field
                                color="#ffab40"
                                placeholder="0-49.99"
                                class="price-input pa-0 ma-0"
                                background-color="rgba(255,255,255,0.1)"
                                dense
                                solo
                                :disabled="isDisabledPrice"
                                hide-details
                                v-model="model.price"
                                type="number"
                                min="0"
                                v-on:input="priceValidation"
                            >
                            </v-text-field>
                        </v-col>
                        <v-col cols="5" class="text-end">
                            {{ $t("dialogs.free") }}
                        </v-col>
                    </v-row>
                </simple-before-posting-block>
            </v-radio-group>
            <span v-if="validation_errors" class="red--text ml-3">
                <strong>* </strong>
                {{ validation_errors }}
            </span>
            <v-row class="no-gutters" align="center" v-if="errors.length">
                <v-col class="no-gutters text-center" cols="12">
                    <span class="deep-orange--text">{{
                        $t("dialogs.something_is_missing")
                    }}</span>
                </v-col>
            </v-row>
        </v-container>

        <v-btn class="story-post-btn mb-3"
            elevation="2"
            x-large
            :disabled="isDisabledPost" 
            :class="{'onclic': isLoading}" 
            @click="send">
            <span class="font-weight-bold" v-if="!isLoading && !isPostUploadSuccess && !isPostUploadFailed">
                {{ $t("dialogs.post") }}
            </span>
            <span v-if="isLoading" class="dot-typing"></span>
            <span class="font-weight-bold" v-if="isPostUploadSuccess">
                {{ $t("dialogs.post_upload.success") }}
            </span>
            <span class="font-weight-bold" v-if="isPostUploadFailed">
                {{ $t("dialogs.post_upload.failed") }}
            </span>
        </v-btn>
        <verify-dialog
            v-if="this.isShowVerifyDialog"
            @close="handleCloseVerifyDialog"
        />
        <insufficient-funds-dialog
            v-if="insufficientFundsError"
            @close="handleCloseInsufficientFundsError"
        />
        <direct-message-dialog
            v-if="showDirectMessageDialog"
            :direct_price="subscribeDmPrice"
            :paid_options="['direct_message']"
            @direct-message="handleDirectMessage"
            @close="handleCloseDirectMessageDialog"
        />
    </before-posting>
</template>

<script>
import BeforePosting from "@/layouts/app/postingLayout/BeforePosting";
import SimpleBeforePostingBlock from "@/components/app/posting/SimpleBeforePostingBlock";
import Checkboxes from "@/components/app/posting/BeforePostingBlock";
import GradientButton from "@/components/app/button/GradientButton";
import AutocompleteHashtags from "@/components/app/form/AutocompleteHashtags";
import VerifyDialog from "@/components/app/dialogs/VerifyDialog";
import {
    CURRENCY_SYMBOL,
    MAX_STORY_DESCRIPTION_LENGTH,
    DEFAULT_STORY_DESTINATION,
    INSUFFICIENT_FUNDS_ERROR,
} from "@/configs/constants";

import { mapActions, mapMutations, mapState } from "vuex";
import DirectMessageDialog from "@/components/app/dialogs/DirectMessageDialog.vue";
import InsufficientFundsDialog from "@/components/app/dialogs/InsufficientFundsDialog.vue";

export default {
    name: "BeforePostingScreen",
    data: () => ({
        errors: [],
        validation_errors: "",
        model: {
            text: "",
            destination: DEFAULT_STORY_DESTINATION,
            tags: [],
            chats: [],
            subscribers: [],
            free: "free",
            price: "",
        },
        lastDestination: DEFAULT_STORY_DESTINATION,
        isFree: "",
        tags: [],
        currency: CURRENCY_SYMBOL,
        storyDescriptionLength: MAX_STORY_DESCRIPTION_LENGTH,
        selfChats: [],
        clearChats: false,
        clearSubscribers: false,
        checkedChats: false,
        checkedSubscribers: false,
        isDisabledPost: false,
        showDirectMessageDialog: false,
        subscribeDmPrice: null,
        subscribeDmUserId: null,
        insufficientFundsError: false,
        isLoading: false,
        isPostUploadSuccess: false,
        isPostUploadFailed: false,
    }),
    components: {
        InsufficientFundsDialog,
        DirectMessageDialog,
        BeforePosting,
        SimpleBeforePostingBlock,
        Checkboxes,
        GradientButton,
        AutocompleteHashtags,
        VerifyDialog,
    },
    computed: {
        middleTextareaText() {
            return this.beforePostingText === "";
        },
        isDisabledPrice() {
            return !this.user.verified;
        },
        ...mapState({
            imageBlob: (state) => state.story.imageBlob,
            videoDataUrl: (state) => state.story.videoDataUrl,
            videoDuration: (state) => state.story.videoDuration,
            videoBlob: (state) => state.story.videoBlob,
            fileResolution: (state) => state.story.fileResolution,
            user: (state) => state.user.user.data,
            type: (state) => state.story.type,
            isMuted: (state) => state.story.isMuted,
            overlayDataUrl: (state) => state.story.overlayDataUrl,
            subscribers: (state) => state.user.user.subscribers,
            isShowVerifyDialog: (state) =>
                state.user.components.isShowVerifyDialog,
        }),
    },
    watch: {
        "model.price"(value) {
            value = Number.parseFloat(value);
            value = isNaN(value) ? 0 : value;
            if (this.model.free === "free" && value) {
                this.model.free = "";
            }

            if (this.model.free === "" && value === 0) {
                this.model.free = "free";
            }
        },
        "model.free"(value) {
            if (this.model.price && value === "free") {
                this.model.price = null;
            }
        },
        "model.chats"(newValue) {
            if (newValue.length > 0) {
                this.model.destination = "chat";
                this.model.tags = [];
            } else {
                this.model.destination = "my_story";
            }
        },
        "model.subscribers"(newValue) {
            if (newValue.length > 0) {
                this.model.destination = "subscribers";
                this.model.tags = [];
            } else {
                this.model.destination = "my_story";
            }
        },
    },
    methods: {
        ...mapMutations(["showSnackBar", "hideVerifyDialog", "setStep"]),
        async send() {
            const chatsToBuyDm = this.selfChats.filter(
                (chat) =>
                    this.model.chats.find((id) => chat.id === id) &&
                    chat?.cantSendMessage === "has_to_buy"
            );

            if (chatsToBuyDm.length) {
                this.subscribeDmUserId = chatsToBuyDm[0].user_id;
                this.subscribeDmPrice = chatsToBuyDm[0]?.directMessagePrice;
                this.showDirectMessageDialog = true;
                return;
            }

            if (this.model.price > 0) {
                const verify = await this.isVerifyUser();
                if (!verify) {
                    return;
                }
            }
            let file = this.type === "photo" ? this.imageBlob : this.videoBlob;
            let data = this.model;
            this.errors = [];
            this.isDisabledPost = true;
            this.isLoading = true;
            this.isPostUploadSuccess = false;
            this.isPostUploadFailed = false;
            this.$store
                .dispatch("sendStory", {
                    model: data,
                    file: file,
                    muted: this.isMuted,
                    height: this.fileResolution.height,
                    width: this.fileResolution.width,
                    overlayDataUrl: this.overlayDataUrl,
                    videoDuration: this.videoDuration,
                    isFrontCamera: this.$core.storyCore.isFrontCamera,
                    isLandscape: this.$core.storyCore.isLandscape,
                    type: this.$store.state.story.type,
                    slowMotionFrame: this.$core.slowMotionFrame,
                    normalFrame:this.$core.normalFrame,
                })
                .then((response) => {
                    this.$auth.fetch();

                    if (this.model.destination === "chat") {
                        this.$router.push({ name: "user.own.messages" });
                    }

                    if (this.$route.name === "user.own.profile.view") {
                        this.getUserStories();
                    }

                    if (response.data.data) {                        
                        this.isDisabledPost = false;
                        this.isLoading = false;

                        this.isPostUploadSuccess = true;
                        setTimeout(() => {
                            this.setStep(-1);
                        }, 2000);
                    }
                })
                .catch((e) => {
                    this.isPostUploadFailed = true;

                    if (e.response.data.err_msg?.price) {
                        this.validation_errors = e.response.data.err_msg.price[0]
                    }
                    if (
                        Array.isArray(e.response.data.err_msg) &&
                        e.response.data.err_msg.length
                    ) {
                        this.showSnackBar(e.response.data.err_msg[0]);
                        this.errors.push(e.response.data.err_msg);
                        this.isDisabledPost = false;
                        this.isLoading = false;
                        return;
                    }
                    const message =
                        e.response.data.message === ""
                            ? e.response.statusText
                            : e.response.data.message;
                    if (message) {
                        this.showSnackBar(message);
                    }
                    this.isDisabledPost = false;
                    this.isLoading = false;
                });
        },
        async handleDirectMessage() {
            await this.buyDirectMessage(this.subscribeDmUserId)
                .then((res) => {
                    if (typeof res.redirectUrl !== "undefined") {
                        this.$window.location = res.redirectUrl;
                    }
                    if (typeof res.success !== "undefined") {
                        this.selfChats = this.selfChats.map((chat) => {
                            if (chat.user_id === this.subscribeDmUserId) {
                                chat.cantSendMessage = null;
                            }
                            return chat;
                        });
                    }
                })
                .catch(({ response }) => {
                    if (response.status === 400) {
                        if (
                            response.data.err_key === INSUFFICIENT_FUNDS_ERROR
                        ) {
                            this.insufficientFundsError = true;
                            this.showDirectMessageDialog = false;
                            return;
                        }
                    }
                    this.showSnackBar(this.$t("messages.something_went_wrong"));
                });
            this.handleCloseDirectMessageDialog();
        },
        buildData(obj) {
            if (Array.isArray(obj)) {
                if (typeof obj[0] !== "object") {
                    return obj.join(",");
                } else {
                    let buildedData = [];
                    for (let i in obj) {
                        buildedData.push(obj[i]["id"]);
                    }
                    return buildedData.join(",");
                }
            } else {
                return obj;
            }
        },
        handleCloseVerifyDialog() {
            this.hideVerifyDialog();
        },
        handleCloseDirectMessageDialog() {
            this.showDirectMessageDialog = false;
        },
        handleCloseInsufficientFundsError() {
            this.insufficientFundsError = false;
        },
        handleChangeRadio(radio) {
            if (radio.value === "chat") {
                this.model.subscribers = [];
                this.clearSubscribers = true;
                this.checkedChats = !this.checkedChats;
                this.checkedSubscribers = false;
                this.clearChats = false;
                this.model.chats = this.selfChats.map((chat) => chat.id);
            }

            if (radio.value === "subscribers") {
                this.model.chats = [];
                this.model.subscribers = this.subscribers.map((s) => s.id);
                this.checkedSubscribers = !this.checkedSubscribers;
                this.checkedChats = false;
                this.clearChats = true;
            }
        },
        onClickDestination(value) {
            if (this.lastDestination === value) {
                this.lastDestination = "";
                this.model.destination = "";
            } else {
                this.lastDestination = value;
                if (this.model.destination === "my_story") {
                    this.clearSubscribers = true;
                    this.clearChats = true;
                }
            }
        },
        onClickFree(value) {
            if (this.isFree === value) {
                this.isFree = "";
                this.model.free = "";
            } else {
                this.isFree = value;
            }
        },
        async onClickPrice() {
            if (this.isDisabledPrice) {
                await this.isVerifyUser();
            }
        },
        priceValidation() {
            if (this.model.price > 49.99) {
                this.isDisabledPost = true;
                this.validation_errors = this.$t('dialogs.max_price_error', { min_dm_price: 0, max_dm_price: 49.99});
            } else {
                this.isDisabledPost = false;
                this.validation_errors = '';
            }
        },
        ...mapActions([
            "getTags",
            "setMessages",
            "getUserStories",
            "isVerifyUser",
            "getOwnSubscribers",
            "buyDirectMessage",
        ]),
    },
    async mounted() {
        this.getTags().then((res) => (this.tags = res));
        await this.getOwnSubscribers();
        let messages = await this.setMessages();
        this.selfChats = messages.data
            .filter(
                (item) =>
                    // item?.isSubscribed &&
                    item?.cantSendMessage !== "turn_off_messages" &&
                    item?.messageCount > 0
            )
            .filter((chat) => !chat.isBroadcastingChannel);
    },
};
</script>

<style scoped lang="scss">
@import '@/sass/modules/_variables';

.title-block__before-posting {
    font-size: 1.1em;
}

.mdi-checkbox-marked {
    color: blue;
}

.block {
    background-color: #1a1a1a;
    border-radius: 16px;
    outline: none;
}

.before-posting__text {
    font-size: 15px;
    color: white;
    width: 100%;
    height: 70px;
    outline: none;
    resize: none;
    border: solid 1px #707070;
    //margin-top: 1.6em;
    padding: 10px 10px 10px 1.1em;
}

.middle-textarea-placeholder {
    line-height: 46px;
}

.post-destination {
    border-radius: 16px;
    font-size: 15px;
    background-color: #1a1a1a;
    color: white;
    width: 100%;
    padding: 0 1em;
    min-height: 60px;
    display: flex;
}

.v-messages {
    display: none;
    min-height: 0;
}

.gradient-checkbox {
    background: -webkit-linear-gradient(#eee, #333);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.linear-gradient--text {
    color: red;
    font-size: 12px;
}

.top-text {
    font-size: 1.7em;
    font-weight: 900;
}

button {
    outline:none;
    height: 50px !important;
    text-align: center;
    border-radius:40px;
    background: $accent-background;
    letter-spacing:1px;
    text-shadow: none;
    cursor: pointer;
    transition: all 0.25s ease;
    width: 80%;
    margin: 0 auto;
}

.onclic {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 12px 5px;
    width: 50px;
    min-width: 50px !important;
    &:before, &:after { 
        display: none;
     }
}

.dot-typing {
    position: relative;
    left: -9999px;
    width: 7px;
    height: 7px;
    border-radius: 5px;
    background-color: #fff;
    color: #fff;
    box-shadow: 9984px 0 0 0 #fff, 9999px 0 0 0 #fff, 10014px 0 0 0 #fff;
    animation: dot-typing 2s infinite linear;
}
 
@keyframes dot-typing {
    0% {
        box-shadow: 9984px 0 0 0 #fff, 9999px 0 0 0 #fff, 10014px 0 0 0 #fff;
    }
    8.33% {
        box-shadow: 9984px 0px 0 0 #fff, 9999px 0 0 0 #fff, 10014px 0 0 0 #fff;
    }
    16.667% {
        box-shadow: 9984px 0px 0 0 #fff, 9999px 0 0 0 #fff, 10014px 0 0 0 #fff;
    }
    24.99% {
        box-shadow: 9984px -6px 0 0 #fff, 9999px 0px 0 0 #fff, 10014px 0 0 0 #fff;
    }
    33.33% {
        box-shadow: 9984px 0 0 0 #fff, 9999px 0 0 0 #fff, 10014px 0 0 0 #fff;
    }
    41.66% {
        box-shadow: 9984px 0 0 0 #fff, 9999px 0 0 0 #fff, 10014px 0px 0 0 #fff;
    }
    49.987% {
        box-shadow: 9984px 0 0 0 #fff, 9999px 0 0 0 #fff, 10014px 0 0 0 #fff;
    }
    58.317% {
        box-shadow: 9984px 0 0 0 #fff, 9999px -6px 0 0 #fff, 10014px 0 0 0 #fff;
    }
    66.66% {
        box-shadow: 9984px 0 0 0 #fff, 9999px 0 0 0 #fff, 10014px 0 0 0 #fff;
    }
    74.97% {
        box-shadow: 9984px 0 0 0 #fff, 9999px 0 0 0 #fff, 10014px 0 0 0 #fff;
    }
    83.30% {
        box-shadow: 9984px 0 0 0 #fff, 9999px 0 0 0 #fff, 10014px 0 0 0 #fff;
    }
    91.637% {
        box-shadow: 9984px 0 0 0 #fff, 9999px 0 0 0 #fff, 10014px -6px 0 0 #fff;
    }
    100% {
        box-shadow: 9984px 0 0 0 #fff, 9999px 0 0 0 #fff, 10014px 0 0 0 #fff;
    }

}
</style>
