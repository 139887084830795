<template>
    <v-row class="ma-0">
        <v-col class="pa-0 line-container" v-for="i in count" :key="i">
            <stores-line
                :active="i === active"
                :loaded="i === active && loaded"
                :time="time"
                :progress="i === active ? progress : (i < active ? 100 : 0)"
            />
        </v-col>
    </v-row>
</template>

<script>
import StoresLine from "@/components/app/story/StoresLine";

export default {
    name: "CountStores",
    components: {
        StoresLine
    },
    props: {
        count: {
            required: true,
            type: Number
        },
        active: {
            default: 1,
            type: Number
        },
        time: {
            type: [String, Number],
            default: 7
        },
        loaded: {
            type: Boolean,
            default: false
        },
        stoped: {
            type: Boolean,
            default: false
        },
        repeat: {
            type: Boolean,
            default: false
        },
        progress: {
            type: Number,
        },
        storyId: {
            type: [String, Number],
            required: true
        }
    },
    data() {
        return {
            timer: false
        }
    },
    watch: {
        stoped() {
            if (this.stoped === true) {
                this.$window.clearTimeout(this.timer)
                this.timer = false
            } else if (this.stoped === false && this.active) {
                this.updateTimerStory()
            }
        },
        active() {
            this.loaded && this.updateTimerStory()
        },
        loaded() {
            this.loaded ? this.updateTimerStory() : this.$window.clearTimeout(this.timer)
        },
        repeat() {
            this.loaded ? this.updateTimerStory() : this.$window.clearTimeout(this.timer)
        },
        storyId(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.updateTimerStory();
            }
        },
    },
    methods: {
        updateTimerStory() {
            if (this.loaded === true) {
                this.$window.clearTimeout(this.timer)
                this.timer = setTimeout(() => {
                    if (this.stoped) {
                        return;
                    }
                    if (this.repeat) {
                        this.updateTimerStory()
                        return
                    }
                    return this.$emit('end')

                }, this.time * 1000)
            }
        }
    },
    mounted() {
        this.loaded && this.updateTimerStory()
    },
}
</script>

<style scoped>
.line-container {
    margin: 0 0.15em;
}
</style>
