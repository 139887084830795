<template>
    <div :class="{'story-layout': true, blur: blur}">
        <div class="overlay overlay-background" v-if="isOverlayShow && type !== 'video'"
             :style="overlayThumbnail ? `background-image: url(${overlayThumbnail}); background-repeat: no-repeat; background-position: center center; background-size: cover;` : ''">
            <slot></slot>
        </div>
        <div class="image-container"
             v-if="type === 'image' || type === 0"
             :style="`background-image: url(${overlayThumbnail}); background-repeat: no-repeat; background-position: center center; background-size: cover;`">
        </div>

        <div class="overlay overlay-background" v-if="isOverlayShow && type == 'video'">
            <slot></slot>
        </div>
        <div class="video-container"
             v-if="type === 'video'"
        >
            <video ref="video"
                   autoplay
                   :muted="!story.isAvailable || isMuted"
                   playsinline
                   class="video"
                   @timeupdate="$emit('update-video-progress')"
                   @ended="$emit('video-story-ended')"
                   :src="videoUrl"
            />
        </div>
        <div
            class="tes overlay-controls"
            :class="{'overlay': showOverlayControls}"
            @dblclick.stop.prevent="$emit('dblmid')"
        >
            <v-container fluid class="h-full pa-0 ma-0">
                <v-row class="h-full">
                    <v-col @click="$emit('left')"></v-col>
                    <v-col @dblclick="$emit('dblclick-mid')" @click="$emit('mid')"></v-col>
                    <v-col @click="$emit('right')"></v-col>
                </v-row>
            </v-container>
        </div>
    </div>
</template>
<script>
import {mapMutations} from "vuex";
export default {
    name: "StoryLayout",
    data: () => ({
        delay: 700,
    }),
    computed: {
        videoUrl() {
            if(this.story.is_slo_mo){
                return this.story.slow_motion_src ? this.story.slow_motion_src : this.story.mp4_video_src
            } else {
                return this.story.webm_video_src ? this.story.webm_video_src : this.story.mp4_video_src
            }
            // return this.story.webm_video_src ? this.story.webm_video_src : this.story.mp4_video_src
        },
        overlayThumbnail() {
            return this.story.overlay_image_src;
        },
        isMuted() {
            return this.story?.meta?.muted;
        }
    },
    props: {
        story: {
            required: true,
            type: Object,
        },
        type: {
            type: [String, Number],
            default: 'image'
        },
        src: {
            required: true,
            type: String,
        },
        blur: {
            type: Boolean,
            default: false
        },
        overlay: {
            type: String
        },
        isOverlayShow: {
            type: Boolean
        },
        isAvailable: {
            type: Boolean
        },
        showOverlayControls: {
            type: Boolean,
            default: true
        },
        stopVideo: {
            type: Boolean,
            default: false
        },
        soundOn: {
            type: Boolean,
            default: false
        },
        repeat: {
            type: Boolean,
            default: false
        },
    },
    watch: {
        story() {
            this.loadContent()
        },
        stopVideo(isStop) {
            const videoEl = this.$refs['video']
            if (videoEl) {
                if (isStop) {
                    videoEl.pause()
                } else {
                    videoEl.play()
                }
            }
        },
    },
    methods: {
        ...mapMutations([
            'setIsLoadedStory',
        ]),
        loadContent() {
            this.$nextTick(() => {
                this.setIsLoadedStory(false)
                if (this.type === 'image') {
                    this.setIsLoadedStory(true)
                }
                if (this.type === 'video') {
                    this.$refs['video'].addEventListener("playing", () => {
                        this.setIsLoadedStory(true)
                    })
                    this.$refs['video'].addEventListener("ended", (event) => {
                        this.setIsLoadedStory(false)
                        this.repeat && this.$refs['video'].play()
                    })
                }
            })
        }
    },
    destroyed() {
        if (this.$refs['video']) {
            this.$refs['video'].removeEventListener("ended")
            this.$refs['video'].removeEventListener("playing")
        }
    },
    created() {
        this.loadContent()
    },
}
</script>
<style scoped lang="scss">
.story-layout {
    background: black;
    height: 100vh;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    object-fit: cover;
}
.story-layout.blur {
    filter: blur(3px)
}
.image-container, .overlay-background, .video-container {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    height: 100%;
    width: 100%;
}
.video-container {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    & video {
        object-fit: cover;
        height: 100%;
    }
}
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    &-controls {
        margin-top: 92px !important;
        margin-bottom: 100px !important;
        height: calc(100vh - 192px) !important;
        min-height: initial;
        z-index: 3;
    }
}
</style>
