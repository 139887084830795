<template>
    <div>
        <story-layout
            ref="storyLayout"
            :story="stories[activeStory]"
            :src="stories[activeStory].overlay_src"
            :overlay="stories[activeStory].overlay_src"
            :is-available="stories[activeStory].isAvailable"
            :is-overlay-show="isOverlayShow"
            :show-overlay-controls="showOverlayControls"
            :stop-video="isStopVideo"
            :sound-on="isSoundOn"
            :repeat="isRepeat"
            v-touch="{
                up: ()=> handleTouchUp(),
                down: ()=> handleTouchDown(),
                start: handleTouchStart,
                end: ()=> handleTouchEnd(),
                move: handleTouchMove,
            }"
            @touchmove.native.prevent="handleTouchMove"
            :type="stories[activeStory].is_video ? 'video' : 'image'"
            @right="handleTouchRight"
            @left="handleTouchLeft"
            @mid="handlePressMiddle"
            @update-video-progress="handleVideoProgress"
            @video-story-ended="nextStory"
        >
            <count-stores
                class="top-story-line"
                :count="stories.length"
                :active="activeStory+1"
                :loaded="isLoadedStory"
                :time="duration"
                :stoped="isStopStory"
                :repeat="isRepeat"
                :progress="progress"
                :story-id="stories[activeStory].id"
                @end="onEnd"
                v-show="showContent"
            />
            <unavailable
                :is-available="!stories[activeStory].isAvailable"
                :price="stories[activeStory].price"
                :user-id="userId"
                :subscribe-price="subscribePrice"
                :subscription="subscriptionBenefits"
                v-if="!stories[activeStory].isAvailable"
                @unlock-story="handleUnlockStory"
            />
            <auth-user-label :show="stories[activeStory].isAvailable"/>
            <bottom-story-block :type="stories[activeStory].type"
                                :slug="stories[activeStory].slug"
                                :text="text"
                                :tags="stories[activeStory].tags"
                                :show-content="showContent"/>
        </story-layout>
        <story-control-block
            class="top-story-block"
            :profile-image="profileImage"
            :name="name"
            :small-text="stories[activeStory].created_date"
            :no-paid="noPaid"
            :user-id="userId"
            :is-available="stories[activeStory].isAvailable"
            :verified="stories[activeStory].verified"
            :is-video="stories[activeStory].is_video"
            :video-meta="stories[activeStory].video_meta"
            :sound-on="isSoundOn"
            :video-duration="duration"
            :is-repeat="isRepeat"
            @repeat="handleRepeat"
            @send="handleSendMessage"
            @tips="handleTips"
            @report="openReportDialog"
            @sound="handleSound"
            @close-story-view="close"
            @open-chat-box="setStopStory(true)"
            @close-chat-box="setStopStory(false)"
            v-show="showContent"
        />
        <tip-menu-dialog
            v-if="isShowTip"
            :target-verified="stories[activeStory].verified"
            :target-username="stories[activeStory].name"
            :has-radio="false"
            :blur="true"
            @close="handleCloseTip"
            @save="sendTip"
        />
        <insufficient-funds-dialog
            v-if="insufficientFundsError"
            @close="handleCloseInsufficientFundsError"
        />
        <app-transition>
            <own-story-menu @close="isShowEdit =  false" v-if="isShowEdit"/>
        </app-transition>
        <app-transition>
            <report-message-dialog
                v-if="showReportDialog"
                @report="handleSendReport"
                @close="closeReportDialog"
            />
        </app-transition>

        <story-guide v-if="isShowGuide" @left="handleTouchRight" @center="handlePressMiddle" @right="handleTouchLeft" @close="removeGuide">
            {{ $t('dialogs.are_you_sure') }}
            <template>
            </template>
        </story-guide>
    </div>

</template>

<script>
import CountStores from "@/components/app/story/CountStores";
import StoryLayout from "@/layouts/app/postingLayout/StoryLayout";
import {mapActions, mapMutations, mapState} from 'vuex'
import StoryControlBlock from "@/components/app/story/StoryControlBlock";
import BottomStoryBlock from "@/components/app/story/BottomStoryBlock";
import AuthUserLabel from "@/components/app/story/AuthUserLabel";
import SwipeTipMenu from "@/components/app/profile/SwipeTipMenu";
import Unavailable from "@/components/app/story/Unavailable";
import OwnStoryMenu from "@/views/app/story/OwnStoryMenu";
import InsufficientFundsDialog from "@/components/app/dialogs/InsufficientFundsDialog";
import ReportMessageDialog from "@/components/app/dialogs/ReportMessageDialog";
import {INSUFFICIENT_FUNDS_ERROR, LATENCY_SHOWING_STORY} from "@/configs/constants";
import TipMenuDialog from "@/components/app/dialogs/TipMenuDialog";
import StoryGuide from "@/components/app/story/StoryGuide";

export default {
    name: "StoryView",
    data: () => ({
        isShowTip: false,
        isShowEdit: false,
        showOverlayControls: true,
        own: false,
        showContent: true,
        insufficientFundsError: false,
        showReportDialog: 0,
        viewNextUserStory: false,
        isShowGuide:false,
        initailGuide:true,
        touchStartX: 0,
        touchStartTime: 0,
        touchDeltaX: 0,
        isDragging: false,
        swipeThreshold: 50,
        isSwiping: false,
        progress: 0,
        imageStoryTimer: null,
        startVideoTime: null,
    }),
    props: {
        startFromActiveIndex: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapState({
            stories: state => state.story.currentStory.stories,
            profileImage: state => state.story.currentStory.profileImage,
            name: state => state.story.currentStory.name,
            creatorId: state => state.story.currentStory.creator_id,
            subscribePrice: state => state.story.currentStory.subscribePrice,
            subscriptionBenefits: state => state.story.currentStory.subscriptionBenefits,
            activeStory: state => state.story.activeStory,
            isOverlayShow: state => state.story.storyView.isOverlayShow,
            isStopStory: state => state.story.storyView.isStopStory,
            isStopVideo: state => state.story.storyView.isStopVideo,
            isLoadedStory: state => state.story.storyView.isLoadedStory,
            isSoundOn: state => state.story.storyView.isSoundOn,
            isRepeat: state => state.story.storyView.isRepeat,
            guide: state => state.story.currentStory.guide,
            auth_id:state => state.story.currentStory.auth_id,
        }),
        isShowOverlay() {
            return !this.isShowEdit && !this.isShowTip
        },
        currentStory() {
            return this.stories[this.activeStory];
        },
        tags() {
            return this.currentStory.type === 'tags' ? this.currentStory.tags : []
        },
        text() {
            return this.currentStory.text
        },
        noPaid() {
            return typeof this.currentStory.noPaid !== 'undefined'
        },
        type() {
            return this.currentStory.type;
        },
        duration() {
            return this.currentStory.video_duration ? this.currentStory.video_duration : LATENCY_SHOWING_STORY;
        },
        userId() {
            return this.currentStory.user_id;
        },
    },
    watch: {
        currentStory(story) {
            this.resetProgress();
            if (!story.is_video) {
                this.startImageProgress();
            }
            this.handleWatchStory(story)
        },
        isShowTip() {
            this.showOverlayControls = !this.isShowTip
        },
    },
    methods: {
        ...mapMutations([
            'setStopStory',
            'toggleStopStory',
            'toggleStopVideo',
            'stopStory',
            'startStory',
            'toggleStoryViewSound',
            'setStoryViewIsRepeat',
            'setActiveStory',
            'stopVideo',
            'startVideo',
        ]),
        ...mapActions([
            'sendMessage',
            'sendTipMessage',
            'sendUserWatchStory',
            'sendReportStory',
            'unlockStory',
            'setOtherUser',
        ]),
        nextStory() {
            if (this.activeStory < this.stories.length - 1) {
                this.setActiveStory(this.activeStory + 1)
                this.viewNextUserStory = false
            } else {
                this.viewNextUserStory = true
                this.nextUserStory()
            }
        },
        nextUserStory() {
            this.$emit('nextUserStory')
        },
        prevStory() {
            if (this.activeStory > 0) {
                this.setActiveStory(this.activeStory - 1)
                this.viewNextUserStory = false
            } else {
                this.viewNextUserStory = true
                this.prevUserStory()
            }
        },
        prevUserStory() {
            this.$emit('prevUserStory')
        },
        onEnd() {
            if (this.stories.length === this.activeStory + 1) {
                this.nextStory()
            } else {
                if(!this.isStopStory) {
                    this.setActiveStory(this.activeStory + 1)
                }
                this.viewNextUserStory = false
            }
        },
        showTipMenu() {
            if (this.own === true) {
                this.isShowEdit = true
            } else {
                this.showOverlayControls = false
                this.isShowTip = true
            }
        },
        close() {
            if (this.isShowTip === true) {
                this.showOverlayControls = true
                this.isShowTip = false
            } else {
                this.$emit('close')
            }
        },
        async sendTip(model) {
            await this.sendTipMessage({
                user_id: this.userId,
                message: model.text,
                story_id: this.currentStory.id,
                ...model
            }).then(() => {
                this.handleSendMessage()
            }).catch((e) => {
                if (e.response.data.err_key === INSUFFICIENT_FUNDS_ERROR) {
                    this.insufficientFundsError = true
                }
            })
        },
        handleCloseTip() {
            this.isShowTip = false
            this.startStory()
        },
        handleTips() {
            this.stopStory()
            this.showTipMenu()
        },
        handleSound() {
            this.toggleStoryViewSound()
        },
        handleSendMessage() {
            // TO DO check send message
            // this.$router.push({name: 'user.own.chat', params: {id: this.creatorId}})
        },
        handleRepeat() {
            this.setStoryViewIsRepeat(!this.isRepeat)
        },
        handleCloseInsufficientFundsError() {
            this.insufficientFundsError = false
        },
        handleTouchUp() {
            this.close()
        },
        handleTouchDown() {
            this.close()
        },
        handleTouchLeft() {
            if (this.isSwiping) return;
            this.prevStory()
            this.removeGuide()
        },
        handleTouchRight() {
            if (this.isSwiping) return;
            this.nextStory()
            this.removeGuide()
        },
        handleTouchStart(event) {
            this.touchStartX = event.touches[0].clientX;
            this.touchStartTime = Date.now();
            this.isDragging = true;
            if (this.stories[this.activeStory].is_video) {
                const videoElement = this.$refs.storyLayout.$refs.video;
                if (videoElement) {
                    this.startVideoTime = videoElement.currentTime;
                }
            }
            this.setStopStory(true)
        },
        handleTouchMove(event) {
            if (!this.isDragging) return;

            const currentX = event.touches[0].clientX;
            this.touchDeltaX = currentX - this.touchStartX;

            // Update video progress only for video stories
            if (this.stories[this.activeStory].is_video) {
                this.isSwiping = true;
                const videoElement = this.$refs.storyLayout.$refs.video;
                if (videoElement) {
                    const videoDuration = videoElement.duration;
                    const adjustment = (this.touchDeltaX / window.innerWidth) * videoDuration;
                    let newTime = this.startVideoTime + adjustment;

                    newTime = Math.max(0, Math.min(videoDuration, newTime));
                    videoElement.currentTime = newTime;
                    this.progress = (newTime / videoDuration) * 100; // As percentage
                }
            }
        },

        // To run the video progress bar continuosly even without hand gestures move
        handleVideoProgress() {
            const video = this.$refs.storyLayout.$refs.video;
            if (!this.stories[this.activeStory].is_video) return;
            if (video && video.duration) {
                this.progress = (video.currentTime / video.duration) * 100;
            }
        },

        startImageProgress() {
            this.imageStoryTimer = setInterval(() => {
				this.progress++;
                if (this.progress >= 100) {
                    this.progress = 100;
                    clearInterval(this.imageStoryTimer);
                }
			}, 100);
        },
        resetProgress() {
            this.progress = 0;
            if (this.imageStoryTimer) {
                clearInterval(this.imageStoryTimer);
            }
        },
        handleTouchEnd() {
            const elapsedTime = Date.now() - this.touchStartTime;
            this.isDragging = false;

            if (Math.abs(this.touchDeltaX) < this.swipeThreshold && elapsedTime < 300) {
                this.isSwiping = false;
            }

            // Reset touch delta
            this.touchDeltaX = 0;
            this.setStopStory(false);
        },
        handlePressMiddle() {
            this.showContent = !this.showContent
            this.toggleStopStory()
            this.toggleStopVideo()
            
            if(this.showContent){
                if(this.initailGuide){
                    this.isShowGuide = true
                } else {
                    this.isShowGuide = false
                }
            } else {
                if(this.initailGuide){
                    this.isShowGuide = false
                }
            }
        },
        handleWatchStory(story) {
            this.sendUserWatchStory(story.id)
        },
        openReportDialog() {
            this.showReportDialog = this.currentStory.id
        },
        closeReportDialog() {
            this.showReportDialog = 0
        },
        handleSendReport(message) {
            this.sendReportStory({story_id: this.showReportDialog, accusation: message}).then(() => {
                this.$store.commit('showSnackBar', this.$t('messages.success'))
            })
            this.closeReportDialog()
        },
        
        //guide function start//
        
        //handleTouchLeft
        removeGuide(){
            axios
                .post(
                    "api/users/update-guide"
                )
                .then((response) => {
                    if (response.data.success) {
                        this.isShowGuide = false
                        this.startStory()
                        this.startVideo()
                    }
                })
                .catch(() => console.log('catch'));
        },
        //guide function start

        async handleUnlockStory() {
            await this.unlockStory(this.stories[this.activeStory].id).then(res => {
                if (res.status) {
                    this.stories[this.activeStory].isAvailable = true
                    this.$emit('reload-story')
                } else if (res.status === false) {
                    this.insufficientFundsError = true
                }
            })
        }
    },
    components: {
        OwnStoryMenu,
        Unavailable,
        SwipeTipMenu,
        BottomStoryBlock,
        AuthUserLabel,
        StoryControlBlock,
        StoryLayout,
        CountStores,
        InsufficientFundsDialog,
        ReportMessageDialog,
        TipMenuDialog,
        StoryGuide
    },
    mounted() {
        // skip already watched stories
        if (!this.startFromActiveIndex) {            
            const activeStoryIndex = this.stories.findIndex((story) => !story.watched);
            this.setActiveStory(activeStoryIndex >= 0 ? activeStoryIndex : 0)
        }

        this.handleWatchStory(this.currentStory)

        if(this.guide){
            this.isShowGuide = true
            this.stopStory()
            this.stopVideo()
        } else {
            this.initailGuide = false
        }
    },
    updated() {
        if(this.viewNextUserStory) {
            const activeStoryIndex = this.stories.findIndex((story) => !story.watched);
            this.setActiveStory(activeStoryIndex >= 0 ? activeStoryIndex : 0)
        }
    }
}
</script>

<style scoped lang="scss">
.top-story-line {
    top:0px;
    left:0px;
    height: 1em;
    width: 100vw;
    padding-top: 0.8em;
    padding-left: 0.7em;
    padding-right: 0.7em;
    z-index: 3;
    position: absolute;
}
.top-story-block {
    position: fixed;
    top:1em;
    left: 0px;
    width: 100vw;
    z-index: 101;
}
</style>
