<template>
    <div :class="{'progress-container': true, 'active': active}">
        <span class="progress-bar" :style="{ width: progress + '%' }"></span>
    </div>
</template>

<script>
export default {
    name: "StoresLine",
    props: {
        active: {
            type: Boolean,
            default: false
        },
        loaded: {
            type: Boolean,
            default: false,
        },
        progress: {
            type: Number,
        },
    },
    watch: {
        loaded() {
            // fixes a bug - video repeat in the progress bar. Fix later
            axios.get('/api/echo')
        },
    },
}
</script>

<style scoped lang="scss">
$not-active: rgba(255, 255, 255, 0.4);

.progress-container {
    width: 100%;
    height: 2px;
    background-color: $not-active;
    border-radius: 2px;
    overflow: hidden;
}
.progress-bar {
    height: 100%;
    width: 0;
    display: block;
    background: white;
    transition: width 0.3s linear;
}
</style>
